import './App.scss';
import { TezosToolkit } from '@taquito/taquito';
import { BeaconWallet } from '@taquito/beacon-wallet';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useLazyQuery, gql } from '@apollo/client';

const myAddress = 'tz2GHSM8dGsMCTUCoiY5wSN55nXXtPZWZtZf';
const GET_TOKENS = gql`
  query TokenQuery($userId: String, $filters: ObjktFilter) {
    user(id: $userId) {
      objkts(filters: $filters) {
        name
        id
        metadata
      }
    }
  }
`;
const preferredNetwork = 'mainnet';

const enabledProjects = ['assembling machine', 'windswept', 'september'];

const urlParams = new URLSearchParams(window.location.search);
let PROJECT_NAME = urlParams.get('project');
if (PROJECT_NAME) {
  PROJECT_NAME = PROJECT_NAME.toLowerCase();
}
if (!enabledProjects.includes(PROJECT_NAME)) {
  PROJECT_NAME = enabledProjects[0];
}

const AppComp = ({ password, Tezos, wallet, setSubmitted }) => {
  const [activeWallet, setActiveWallet] = useState(null);
  const [account, setAccount] = useState(null);
  const [selectedToken, setSelectedToken] = useState('');
  const [choiceConfirmed, setChoiceConfirmed] = useState(false);
  const [getTokens, { data: tokenData }] = useLazyQuery(GET_TOKENS);

  useEffect(() => {
    const fetchWallet = async () => {
      Tezos.setWalletProvider(wallet);

      const activeAccount = await wallet.client.getActiveAccount();
      if (activeAccount) {
        setActiveWallet(wallet);
        setAccount(activeAccount);
      }
    };
    fetchWallet();
  }, [Tezos, wallet]);

  useEffect(() => {
    if (account) {
      const { address } = account;
      getTokens({
        variables: {
          userId: address,
          filters: {
            author_in: myAddress,
          },
        },
      });
    }
  }, [account, getTokens]);

  const setupWallet = async () => {
    // const options = {
    //   name: 'teaboswell print verify',
    //   preferredNetwork: 'mainnet',
    // };
    // const wallet = new BeaconWallet(options);

    // The Beacon wallet requires an extra step to set up the network to connect to and the permissions:
    await wallet.requestPermissions({
      network: {
        type: 'mainnet',
      },
    });

    Tezos.setWalletProvider(wallet);
    const activeAccount = await wallet.client.getActiveAccount();
    setActiveWallet(wallet);
    setAccount(activeAccount);
  };

  const unsync = async () => {
    await activeWallet.clearActiveAccount();

    try {
      await activeWallet.getPKH();
    } catch {
      setActiveWallet(null);
      setAccount(null);
    }
  };

  let navigate = useNavigate();
  const submitHandler = e => {
    e.preventDefault();
    let myForm = document.getElementById('print-choice');
    let formData = new FormData(myForm);
    formData.append('password', password);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => navigate('/success'))
      .catch(error => alert(error));
  };

  let tokenList = tokenData ? tokenData.user.objkts : [];
  tokenList = tokenList.filter(token => token.name.toLowerCase().startsWith(PROJECT_NAME));
  // tokenList = [tokenList[0]];
  return (
    <div className='App'>
      {activeWallet === null ? (
        <div className='sync'>
          <span>Begin by syncing your wallet to select the token you would like printed</span>
          <button className='sync-button' onClick={setupWallet}>
            Sync
          </button>
        </div>
      ) : (
        <>
          {choiceConfirmed && (
            <>
              <span>Choice confirmed: {selectedToken}!</span>
            </>
          )}
          <div className='header'>
            <div>Showing available tokens for {account.address}</div>
            <button className='unsync-button' onClick={unsync}>
              Unsync
            </button>
            {selectedToken && (
              <form id='print-choice' method='POST' action='/success' onSubmit={submitHandler}>
                <input type='hidden' name='form-name' value='print-choice' />
                <input type='hidden' name='token' value={selectedToken} />
                <input type='hidden' name='wallet' value={account.address} />
                <input type='hidden' name='password' value={password} />
                <button
                  className='confirm-button'
                  type='submit'
                  name='token-button'
                  onClick={() => {
                    setChoiceConfirmed(true);
                    setSubmitted(true);
                  }}
                >
                  Confirm Choice ({selectedToken})
                </button>
              </form>
            )}
          </div>
          <div className='token-list'>
            {tokenList.map(({ metadata: { name, thumbnailUri } }) => {
              const thumbnailUrl = `https://gateway.fxhash.xyz/ipfs/${
                thumbnailUri.split('ipfs://')[1]
              }`;
              // const thumbnailUrl = 'https://via.placeholder.com/300x400';
              return (
                <div className='token' key={name} onClick={() => setSelectedToken(name)}>
                  <img
                    className={
                      selectedToken === name
                        ? 'selected-img'
                        : selectedToken !== ''
                        ? 'unselected-img'
                        : ''
                    }
                    src={thumbnailUrl}
                    alt={name}
                  />
                  <span className={selectedToken === name ? 'selected-text' : ''}>{name}</span>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

const Success = ({ password }) => {
  const [clicked, setClicked] = useState(false);
  return (
    <div className='success'>
      <span>
        Thank you! Please copy the code below and paste it in the "Code" field of your order form.
      </span>
      <span className='password'>{password}</span>
      <button
        className={clicked ? 'copy clicked' : 'copy'}
        onClick={() => {
          navigator.clipboard.writeText(password);
          setClicked(true);
        }}
      >
        {clicked ? 'Copied!' : 'Copy to clipboard'}
      </button>
      <span>Once copied, you can close this page.</span>
    </div>
  );
};

const App = () => {
  const password = Math.floor(100000 + Math.random() * 900000);
  const Tezos = new TezosToolkit('https://rpc.tzbeta.net/');
  const wallet = new BeaconWallet({ name: 'teaboswell print verify', preferredNetwork });
  const [submitted, setSubmitted] = useState(false);
  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={
            <AppComp
              password={password}
              setSubmitted={setSubmitted}
              Tezos={Tezos}
              wallet={wallet}
            />
          }
        />
        {submitted && <Route path='/success/' element={<Success password={password} />} />}
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </Router>
  );
};

export default App;
